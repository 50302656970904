import { Link } from "react-router-dom";

export default function ProSelector() {
    return(      
        <div className="pro-selector">
        <div className="pro-selector__menu">
            <Link className="circle-button" to="/EIM01">
                Fenilcetonuria
            </Link>

            <Link className="circle-button" to="/EIM02">
                Aciduria glutárica tipo 1
            </Link>


            <Link className="circle-button" to="/EIM03">
                Enfermedad de la orina con olor a jarabe de arce clásica
            </Link>


            <Link className="circle-button" to="/EIM04">
                Acidemia propiónica
            </Link>


            <Link className="circle-button" to="/EIM05">
                Acidemia metilmalónica
            </Link>


            <Link className="circle-button" to="/EIM06">
                Acidemia isovalérica
            </Link>


            <Link className="circle-button" to="/EIM07">
                Homocistinúria
            </Link>


            <Link className="circle-button" to="/EIM08">
                Trastornos del ciclo de la urea
            </Link>
        </div>
    </div>
    );
}