import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Foot(){

    const [imgUrl, updateImg] = useState("./img/sec3.png");

    var divStyle = {
        backgroundImage: 'url(' + imgUrl + ')',
        height: '400px'
    }

    let loc = useLocation();

    useEffect(()=> {
        if(window.location.href.split("/")[window.location.href.split("/").length - 1] === "nosotros") updateImg("./img/sec3.png");
        else if(window.location.href.split("/")[window.location.href.split("/").length - 1] == "ui") updateImg("./img/sec4.png");
        else if(window.location.href.split("/")[window.location.href.split("/").length - 1] == "news") updateImg("./img/sec3.png");
        else if(window.location.href.split("/")[window.location.href.split("/").length - 1] == "EIM01") updateImg("./img/sec4.png");
        else updateImg("./img/sec3.png");
        
    }, [loc]);
    return (
        <section style={divStyle}>
            <div className="sec-footer">
                <div>
                    <Link className="circle-button circle-button__org" to="../contacto">+ Contacto</Link>
                </div>
                
                <div className="socials">
                    <a target="_blank" href="https://www.facebook.com/mito.therapies.3">
                        <img src="./img/fb.png" />
                    </a>
                    <a target="_blank" href="https://www.youtube.com/channel/UC4C50mq_dVhnJMWNBAOT08w">
                        <img src="./img/yt.png" />
                    </a>
                    <a target="_blank" href="https://www.instagram.com/mitotherapies/?hl=es-la">
                        <img src="./img/ig.png" />
                    </a>
                </div> 
                <p>
                    <a className="copy-right" target="_blank" href="../POL-GA-01TratamientoDatosPersonales.pdf">
                        TRATAMIENTO DE DATOS PERSONALES
                    </a>
                </p> 
                <p>
                    Copyright © 2022 mitoTherapies
                </p>
            </div>
        </section>
    );
}