import WelcomeTypeOne from "./components/welcome/WelcomeTypeOne";
import WelcomeTypeTwo from "./components/welcome/WelcomeTypeTwo";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useEffect } from "react";

export default function Home() {

    gsap.registerPlugin(ScrollToPlugin);
    // wait until DOM has been rendered
    useEffect(() => {
        gsap.to(window, {duration: 1, scrollTo: "#homehead"});
    });

    return (
        <div>
            <WelcomeTypeOne/>
            <WelcomeTypeTwo />
        </div>
    );
}