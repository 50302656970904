import Foot from "./components/Foot";
import MastHead from "./components/MastHead";
import WelcomeTypeOne from "./components/welcome/WelcomeTypeOne";

export default function Metabolics(props) {
    return (
        <div>
            <section className="product-header">
                <div className="product-header__imgrack">
                    <img src="./img/prods/01.png" />
                    <img src="./img/prods/02.png" />
                    <img src="./img/prods/03.png" />
                    <img src="./img/prods/04.png" />
                    <img src="./img/prods/05.png" />
                    <img src="./img/prods/06.png" />
                </div>
                <h1>{props.title}</h1>
                <h2>para el tratamiento aminoacidopatías y acidemias orgánicas.</h2>

                <div className="product-header__imgrack">
                    <img src="./img/prods/07.png" />
                    <img src="./img/prods/08.png" />
                    <img src="./img/prods/09.png" />
                    <img src="./img/prods/10.png" />
                    <img src="./img/prods/11.png" />
                </div>
            </section>

            <section className="products">
                <div className="produ">
                    <img src="img/prods/01.png" />
                    <div className="produ__txtbox_g">
                        <h1>GAC MED A PLUS Para menores de 1 año</h1>
                        <p>Patología: Aciduria Glutárica tipo 1</p>
                    </div>
                </div>

                <div className="produ">
                    <img src="img/prods/02.png" />
                    <div className="produ__txtbox_b">
                        <h1>MSUD MED A PLUS Para menores de 1 año</h1>
                        <p>Patología: Enfermedad de la orina con olor a jarabe de arce.</p>
                    </div>
                </div>

                <div className="produ">
                    <img src="img/prods/03.png" />
                    <div className="produ__txtbox_g">
                        <h1>GAC MED B
                            Para mayores 
                            de 1 año
                            </h1>
                        <p>Patología:
                            Aciduria Glutárica 
                            tipo1</p>
                    </div>
                </div>

                <div className="produ">
                    <img src="img/prods/04.png" />
                    <div className="produ__txtbox_b">
                        <h1>MSUD MED B
                            Para mayores 
                            de 1 año
                            </h1>
                        <p>Patología: 
                            Enfermedad de 
                            la orina con olor 
                            a jarabe de arce.</p>
                    </div>
                </div>

                <div className="produ">
                    <img src="img/prods/05.png" />
                    <div className="produ__txtbox_p">
                        <h1>OAC MED A PLUS
                            Para menores 
                            de 1 año
                            </h1>
                        <p>Patología: 
                            Acidemicas Orgánicas. Acidemia Propiónica / Acidemia Metilmalónica
                            </p>
                    </div>
                </div>

                <div className="produ">
                    <img src="img/prods/06.png" />
                    <div className="produ__txtbox_v">
                        <h1>URC MED A PLUS
                            Para menores 
                            de 1 año</h1>
                        <p>Patología: 
                            Desórdenes del Ciclo de la Urea</p>
                    </div>
                </div>



                <div className="produ">
                    <img src="img/prods/07.png" />
                    <div className="produ__txtbox_v">
                        <h1>OAC MED B
                            Para mayores 
                            de 1 año</h1>
                        <p>Patología: 
                            Acidemicas Orgánicas. Acidemia Propiónica / Acidemia Metilmalónica</p>
                    </div>
                </div>



                <div className="produ">
                    <img src="img/prods/08.png" />
                    <div className="produ__txtbox_v">
                        <h1>URC MED B
                            Para mayores 
                            de 1 año</h1>
                        <p>Patología: 
                            Desórdenes del Ciclo de la Urea</p>
                    </div>
                </div>



                <div className="produ">
                    <img src="img/prods/09.png" />
                    <div className="produ__txtbox_o">
                        <h1>LEU MED B
                            Para mayores 
                            de 1 año</h1>
                        <p>Patología: 
                            Acidemia Isovalérica / Hiperleucinemias</p>
                    </div>
                </div>



                <div className="produ">
                    <img src="img/prods/10.png" />
                    <div className="produ__txtbox_e">
                        <h1>HCYS MED B
                            Para mayores 
                            de 1 año
                            </h1>
                        <p>Patología: Homocistinuria</p>
                    </div>
                </div>



                <div className="produ">
                    <img src="img/prods/11.png" />
                    <div className="produ__txtbox_g2">
                        <h1>PKU MED B
                            Para mayores 
                            de 1 año
                            </h1>
                        <p>Patología:
                            Fenilcetonuria</p>
                    </div>
                </div>
            </section>

            <WelcomeTypeOne />
        </div>
    );
}