import WeAreMito from "./components/aboutus/WeAreMito";
export default () => <WeAreMito/>

//1 liner option
// export default () => <WeAreMito/>

//2 liner option
/* const About = () => <WeAreMito/>
export default About; */

//multiline op
/*

export default function About() {
    return(
        <WeAreMito />
    );
}

*/