import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ProSelector from "../components/ProSelector";

export default function EIM06(){
    const boxRef = useRef();
    gsap.registerPlugin(ScrollToPlugin);
    // wait until DOM has been rendered
    useEffect(() => {
        gsap.to(window, {duration: 1, scrollTo: "#wearemito"});
        //gsap.to(boxRef.current, { opacity: "1.0", duration: 1, delay: 1.0 });
    });

    return (
        <section className="wearemito">
            <div id="wearemito" className="wearemito-upnav">
                <h1>
                    <span>Línea </span>EIM
                </h1>
                <div className="wearemito-upnav__close_button">
                    <Link className="wearemito-upnav__link" to="/EIM">
                        VOLVER <span>X</span>
                    </Link>
                </div>
            </div>

            <div className="wearemito-header__title">
                <h1>
                Acidemia isovalérica
                </h1>
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">
                    <div className="wearemito-header__titu-box_area">
                        <h1>
                            <span className="wearemito-header__titu">
                                Definición de la enfermedad 
                            </span>
                        </h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico01.png" />
                    </div>
                </div>

                <p>
                Es una aciduria orgánica, autosómica recesiva, poco frecuente caracterizada por
una presentación clínica variable que va desde una descompensación metabólica
aguda de inicio neonatal hasta un inicio tardío con manifestaciones crónicas
inespecíficas, incluyendo fallo de medro y/o retraso psicomotor. Todos los
pacientes son propensos a una descompensación metabólica aguda e
intermitente. Durante los episodios metabólicos, los análisis de orina muestran
unos niveles elevados de derivados del ácido isovalérico.
                </p>
            </div>

            <div className="wearemito-header__title">
                <h1>
                    Resumen
                </h1>
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">
                    <div className="wearemito-header__titu-box_area">
                        <h1>
                            <span className="wearemito-header__titu">
                                Epidemiología 
                            </span>
                        </h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico02.png" />
                    </div>
                </div>

                <p>
                No se dispone de datos precisos sobre la prevalencia. Las estimaciones más
precisas provienen de estudios de cribado neonatal que estiman la prevalencia al
nacimiento entre 1/50.000-150.000.
                </p>   
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">
                    <div className="wearemito-header__titu-box_area">
                        <h1>
                            <span className="wearemito-header__titu">
                                Descripción clínica 
                            </span>
                        </h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico03.png" />
                    </div>
                </div>

                <p>
                Los pacientes se presentan dentro de un amplio espectro. La presentación
neonatal aguda se caracteriza por el inicio en las dos primeras semanas de vida de
vómitos, crisis epilépticas y letargia, progresando a coma. La acidosis metabólica
con aumento de anion gap es evidente en la evaluación bioquímica. Puede haber
hiperamonemia. El inicio tardío es relativamente inespecífico con fallo de medro
y/o retraso psicomotor. Los pacientes que sobreviven a una presentación aguda
temprana son posteriormente indistinguibles de aquellos que presentan el
fenotipo crónico.<br></br><br></br> Todos los pacientes son propensos a episodios agudos
intermitentes de descompensación con enfermedades leves. La acidosis
metabólica de inicio en la infancia suele producirse por un ayuno prolongado, un
aumento de la ingesta de alimentos ricos en proteínas o infecciones, pudiendo ser
letal en ausencia de un tratamiento inmediato. Puede presentarse el característico
olor del ácido isovalérico, similar al de ''pies sudados'' /sudor c orporal. Aunque en
algunos pacientes se describe un grave retraso del desarrollo psicomotor y
secuelas neurológicas, es probable que estén asociadas a las presentaciones
bioquímicas graves.
                </p>   
            </div>

            <ProSelector />
        </section>
    );
}