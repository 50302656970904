import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ProSelector from "../components/ProSelector";

export default function EIM04(){
    const boxRef = useRef();
    gsap.registerPlugin(ScrollToPlugin);
    // wait until DOM has been rendered
    useEffect(() => {
        gsap.to(window, {duration: 1, scrollTo: "#wearemito"});
        //gsap.to(boxRef.current, { opacity: "1.0", duration: 1, delay: 1.0 });
    });

    return (
        <section className="wearemito">
            <div id="wearemito" className="wearemito-upnav">
                <h1>
                    <span>Línea </span>EIM
                </h1>
                <div className="wearemito-upnav__close_button">
                    <Link className="wearemito-upnav__link" to="/EIM">
                        VOLVER <span>X</span>
                    </Link>
                </div>
            </div>

            <div className="wearemito-header__title">
                <h1>
                    Acidemia propiónica
                </h1>
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">
                    <div className="wearemito-header__titu-box_area">
                        <h1>
                            <span className="wearemito-header__titu">
                                Definición de la enfermedad 
                            </span>
                        </h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico01.png" />
                    </div>
                </div>

                <p>
                La acidemia propiónica (AP) es una aciduria orgánica causada por deficiencias en la
actividad de la propionil CoA-carboxilasa, y se caracteriza por episodios
potencialmente mortales de descompensación metabólica y disfunción
neurológica que se pueden complicar por miocardiopatía.
                </p>
            </div>

            <div className="wearemito-header__title">
                <h1>
                    Resumen
                </h1>
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">
                    <div className="wearemito-header__titu-box_area">
                        <h1>
                            <span className="wearemito-header__titu">
                                Epidemiología 
                            </span>
                        </h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico02.png" />
                    </div>
                </div>

                <p>
                La prevalencia es, probablemente, de 1 por cada 100.000 nacimientos vivos en
todo el mundo. En algunos países, como Arabia Saudita, se observa una alta tasa
de prevalencia.
                </p>   
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">
                    <div className="wearemito-header__titu-box_area">
                        <h1>
                            <span className="wearemito-header__titu">
                                Descripción clínica 
                            </span>
                        </h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico03.png" />
                    </div>
                </div>

                <p>
                La acidemia propiónica se puede presentar en una de las formas siguientes: inicio
neonatal grave, inicio tardío intermitente o forma crónica progresiva. En la forma
de aparición neonatal grave, los niños afectados presentan síntomas de
intoxicación metabólica (mala alimentación, vómitos, sensorio alterado), y
pancitopenia en un periodo que va de las pocas horas a las semanas después del
nacimiento. En la forma de inicio tardío intermitente, la enfermedad se presenta
después de un año o más de vida con episodios de descompensación metabólica
provocada por periodos de respuesta catabólica a situaciones de estrés como
fiebre, vómitos y traumatismos. <br></br><br></br>Los pacientes también pueden presentar crisis
neurológicas agudas caracterizadas por distonía, rigidez, coreoatetosis y
demencia (debida al infarto de los ganglios basales). En la forma crónica
progresiva, la enfermedad se presenta como retraso del crecimiento, vómitos
crónicos, retraso psicomotor, hipotonía, convulsiones y trastornos del
movimiento. Otras complicaciones conocidas son discapacidad intelectual,
neuropatía óptica, miocardiopatía, síndrome de QT largo, pancreatitis, dermatitis
y disfunción inmunitaria.
                </p>   
            </div>

            <ProSelector />
        </section>
    );
}