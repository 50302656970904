import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useEffect, useRef } from "react";

export default function ContactCard() {
    const boxRef = useRef();
    gsap.registerPlugin(ScrollToPlugin);
    useEffect(() => {
        gsap.to(window, {duration: 1, scrollTo: "#contactmito"});
        gsap.to(boxRef.current, { opacity: "1.0", duration: 1, delay: 1.0 });
    });

    return (
        <div id="contactmito" ref={boxRef} className="wearemito-box contact-card">
            <div className="wearemito-box__column">    
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.526876872198!2d-74.05421918552291!3d4.678081496601976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9a92c1551817%3A0xe6b913ed2005150d!2sCra.%2016%20%2393-86%2C%20Bogot%C3%A1%2C%20Colombia!5e0!3m2!1ses-419!2sar!4v1657637888018!5m2!1ses-419!2sar" width="100%" height="450" loading="lazy"></iframe>    
            </div> 
            <div className="wearemito-box__column">
                <p>Carrera 16 #93-86.<br></br>Oficina 805.<br></br>Torre Seki<br></br><br></br>
                ✆ (601) 5645109<br></br><br></br>
                ✉ dircomercial@mitotherapies.com<br></br>
                ✆ 3102717858<br></br><br></br>
                ✉ posventa@mitotherapies.com<br></br>
                ✆ 3042896945<br></br>
</p>
            </div>
        </div>
    );
}