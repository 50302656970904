import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Purpura(){
    gsap.registerPlugin(ScrollToPlugin);
    useEffect(() => {
        gsap.to(window, {duration: 1, scrollTo: "#wearemito"});
    });

    return (
        <section className="wearemito">
            <div id="wearemito" className="wearemito-upnav">
                <h1><span>Línea </span>Púrpura</h1>
                <div className="wearemito-upnav__close_button">
                    <Link className="wearemito-upnav__link" to="/">VOLVER <span>X</span></Link>
                </div>
            </div>
            <div className="wearemito-header__title">
                <h1>¿Qué es Epilepsia Refractaria?:</h1>
            </div>
            <div className="wearemito-header">
                <p>Aquella en la cual se ha producido el fracaso a 2 ensayos de fármacos antiepilépticos (FAE), en monoterapia o en combinación, tolerados, apropiadamente elegidos y empleados de forma adecuada, para conseguir la “ausencia mantenida de crisis*”.<br></br><br></br>
*Se considera ausencia mantenida de crisis a un periodo de un año o, en caso de crisis muy esporádicas, un periodo de al menos el triple al mayor intervalo intercrisis pretratamiento, escogiéndose el que sea mayor de ellos.
</p>
            </div>

            <div className="wearemito-box2">
    
            <div className="wearemito-header">
                <h1><span className="wearemito-header__titu">KetoVOLVE® </span></h1>
                <p>Alimento para propósitos médicos especiales, Fórmula para enfermedades específicas a base de grasas y triglicéridos de cadena media con relación 4:1, para uso en personas mayores de 1 año, para Dieta Cetogénica en Epilepsia Refractaria.</p>
            </div>

                <div className="wearemito-box__column">    
                    <img src="img/keto.png" />   
                </div> 
            </div>

            <div className="wearemito-header__title">
                <h1>Características:</h1>
            </div>

            <div className="wearemito-header">
                <p>*El 21 % del contenido total de grasas del KetoVOLVE® son TCM, que permiten alcanzar un estado de cetosis rápida y estable, mejoran la absorción y potencian el efecto antiepiléptico de KetoVOLVE®, en la terapia cetogénica.</p>

                <p>• Como alimento único, cubre los requerimientos nutricionales de niños de 1 a 10 años.<br></br><br></br>
• Dosificado correctamente en una terapia cetogénica mixta, aporta los micronutrientes requeridos por los pacientes de cualquier edad con epilepsia refractaria.<br></br><br></br>
• Tiene una tasa cetogénica con relación 4:1.<br></br><br></br>
• Es el único producto en polvo en relación 4:1 que contiene Triglicéridos de Cadena Media (TCM), aprobado por Invima para el manejo de Terapia Cetogénica en Epilepsia Refractaria.<br></br><br></br>
• Puede usarse concomitantemente con anticonvulsivantes, antes y después de una cirugía de epilepsia y con un estimulador del nervio vago.<br></br><br></br>
• Contiene 80% de proteína de suero (WHEY) de alto valor biológico(VB=104), y caseinato de sodio.<br></br><br></br>
• Cuenta con un perfil saludable de grasas, libre de grasas trans y cubre los requerimientos de ácidos grasos esenciales.<br></br><br></br>
• Libre de GMO (no ha sido genéticamente modificado).<br></br><br></br>
• Libre de gluten.<br></br><br></br>
• Libre de sacarosa o edulcorantes artificiales.
</p>
            </div>

            <div className="wearemito-header">
                <h1><span className="wearemito-header__titu">Recomendaciones de uso: En Epilepsia Refractaria a fármacos antiepilépticos.1 </span></h1>
                <p>- Deficiencia de GLUT-1<br></br>
- Deficiencia de Piruvato Deshidrogenasa (PDH)<br></br>
- Síndrome de West (espasmos infantiles)<br></br>
- Síndrome de Lennox-Gastaut<br></br>
- Síndrome de Doose<br></br>
- Síndrome de Dravet<br></br>
- Síndrome de Angelman<br></br>
- Síndrome de Ohtahara<br></br>
- Desorden mitocondrial del complejo 1 de la cadena respiratoria<br></br>
- FIRES<br></br>
- Estatus epiléptico súper refractario (EESR)<br></br>
- Esclerosis tuberosa<br></br><br></br>


Referencia 1. Epilepsia Open, 3 (2) : 175-192, 2018
</p>
            </div>
        </section>
    );
}