import { Link, NavLink } from "react-router-dom";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-dom/test-utils';

const AutoplaySlider = withAutoplay(AwesomeSlider);

export default function MastHead() {
    return (
        <header>
            <AutoplaySlider
                play={true}
                bullets = {true}
                organicArrows = {false}
                cancelOnInteraction={false}
                interval={10000}
            >
                <div>
                    <img src="../img/carrusel1.jpg" alt="Mitotherapies"/>
                </div>
                <div>
                    <img src="../img/carrusel2.jpg" alt="Mitotherapies"/>
                </div>
            </AutoplaySlider>

            <div className="header-main">
                <div className="navbar">
                    <Link className="logo" to="../">
                        <img src="../img/logo.png" alt="Mitotherapies"/>
                    </Link>
            
                    <nav>
                        <ul>
                            <li>
                                <NavLink 
                                    to="../nosotros" className={({ isActive }) => (isActive ? 'circle-button_unselected' : 'circle-button')}>
                                        + Nosotros
                                </NavLink>
                            </li>
                            <li>
                                <NavLink 
                                    to="../purpura" className={({ isActive }) => (isActive ? 'circle-button_unselected' : 'circle-button')}>
                                    + Línea Púrpura
                                </NavLink>
                            </li>
                            <li>
                                <NavLink 
                                    to="../eim" className={({ isActive }) => (isActive ? 'circle-button_unselected' : 'circle-button')}>
                                    + Línea EIM
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
                 

                <div className="lowerNav">
                        <Link className="lowerNav--logo" to="/metabolics">
                            <img src="../img/LogoProdsEIM.png" alt="Mitotherapies"/>
                        </Link>

                        <a className="lowerNav--logo" target="_blank" rel="noreferrer" href="https://www.facebook.com/groups/490658588893708">
                            <img src="../img/LogoKetoAmigos.png" alt="Mitotherapies"/>
                            <br></br>

                        <div className="lowerNav--caption">Si eres usuario de productos ingresa aquí</div> 
                        </a>
                    </div>
            </div>
        </header>
    );
}