import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

export default function WeAreMito(){
    const boxRef = useRef();
    gsap.registerPlugin(ScrollToPlugin);
    // wait until DOM has been rendered
    useEffect(() => {
        gsap.to(window, {duration: 1, scrollTo: "#wearemito"});
        //gsap.to(boxRef.current, { opacity: "1.0", duration: 1, delay: 1.0 });
    });

    return (
        <section className="wearemito">
            <div id="wearemito" className="wearemito-upnav">
                <h1>Quienes <span>Somos</span></h1>
                <div className="wearemito-upnav__close_button">
                    <Link className="wearemito-upnav__link" to="/">VOLVER <span>X</span></Link>
                </div>
            </div>
            <div className="wearemito-header__title">
                <h1>NOSOTROS</h1>
            </div>
            <div className="wearemito-header">
                <h1><span className="wearemito-header__titu">MitoTherapies es una empresa colombiana </span>fundada en el año 2017, con presencia en varios países de Latinoamérica.</h1>
                <p>Pionera en el desarrollo e implementación de productos con altos estándares de calidad, tecnología de punta y amplia experiencia en las áreas de los Errores Innatos del Metabolismo y Epilepsia Refractaria a Fármacos antiepilépticos. <br></br><br></br>Somos una compañía que cuenta con el equipo de trabajo con mayor experiencia en el sector, creada para brindar tratamientos de manera efectiva y oportuna a los usuarios / clientes con Enfermedades Huérfanas y Epilepsia en Colombia y Latinoamérica, enfocados en Terapias Cetogénicas para Epilepsia Refractaria y en fórmulas especiales para Errores Innatos del Metabolismo.</p>
            </div>

            <div ref={boxRef} className="wearemito-box">
                <div className="wearemito-box__column">    
                    <img src="./img/news.png" />    
                </div> 
                <div className="wearemito-box__column">
                <h1>NUESTRA MISIÓN</h1>
                    <p>En MitoTherapies SAS desarrollamos soluciones terapéuticas innovadoras a través de la investigación, comercialización y distribución de Alimentos con Propósitos Médicos Especiales (APME) para tratar enfermedades neuro-metabólicas como: los errores innatos del metabolismo de las proteínas y patologías que requieran manejo con terapia cetogénica, para lograr el objetivo terapéutico propuesto y así mejorar la calidad de vida de los usuarios y sus familias. Generamos un impacto positivo a nuestras partes interesadas, apoyados en recursos financieros y físicos óptimos, así como con un equipo humano especializado enmarcados en una cultura de mejora continua.</p>
                </div>
            </div>

            <div className="wearemito-box2">
                
                <div className="wearemito-box__column">
                    <h1>NUESTRA VISIÓN</h1>
                    <p>Ser reconocidos en el año 2027 como la empresa multilatina líder e innovadora en productos especializados, siendo un referente en la industria Farmacéutica con presencia relevante en el mercado nacional e internacional, respaldada por un amplio portafolio que nos permita mejorar la calidad de vida de los usuarios, generando confianza y respaldo a nuestras partes interesadas.</p>
                </div>

                <div className="wearemito-box__column">    
                    <img src="./img/news2.jpg" />    
                </div> 
            </div>
        </section>
    );
}