import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import WelcomeTypeOne from "./components/welcome/WelcomeTypeOne";
import WelcomeTypeTwo from "./components/welcome/WelcomeTypeTwo";

export default function News(){

    const tx = "P <0,02";

    const boxRef = useRef();
    gsap.registerPlugin(ScrollToPlugin);
    // wait until DOM has been rendered
    useEffect(() => {
        gsap.to(window, {duration: 1, scrollTo: "#wearemito"});
        //gsap.to(boxRef.current, { opacity: "1.0", duration: 1, delay: 1.0 });
    });

    return (
        <section className="wearemito">
            <div id="wearemito" className="wearemito-upnav">
                <h1>Keto <span>News</span></h1>
                <div><img src="./img/vol1.png" /></div>
            </div>


            <div className="wearemito-box2">
                
                <div className="wearemito-box__column">
                    <div className="newsTi">   
                        <h1 className="newsTitu"><span className="newsTitu-span">¿Por qué </span>Triglicéridos de cadena media (TCM) <span className="newsTitu-span">en la Terapia Cetogénica?</span></h1>
                    </div>
                </div>

                <div className="wearemito-box__column">   
                <br></br> 
                    <img src="./img/news.png" />    
                </div> 
            </div>
            
            <div className="wearemito-header">
                <p>La Terapia Cetogénica (TC), ha evolucionado en el tiempo y a medida que los mecanismos de acción se dilucidan con más precisión y a la luz de los mecanismos directos e indirectos, epigéneticos y antiinflamatorios activados por ácidos grasos, como es el caso de los triglicéridos de cadena media (TCM) C8 y C10, ha permitido hacer una terapia más específica y el desarrollo de nuevos productos más especializados. <br></br><br></br>
                
                Como antecedente conocemos que la dieta cetogénica (DC) se introdujo por primera vez como tratamiento antiepiléptico, en 1921 (Wilder 1921),y hasta 1976 se evidenció que la DC con TCM era segura y eficaz.
                
                <br></br><br></br>

                Gracias a la publicación del Dr Huttenlocher en 1976; que evaluó los efectos metabólicos de un nuevo régimen cetogénico en el que la cetonemia es inducida por una dieta con TCM aportando un 60% del requerimiento energético diario, y las comparaciones se hicieron con los efectos de la DC clásica 3:1.<br></br> <br></br>
                Este estudio fue realizado con una muestra de 22 niños, dieciocho niños mantenidos en dieta con TCM y 4 con DC 3:1 durante un periodo de 3 meses hasta 4 años, en cuanto a los niveles plasmáticos de B-hidroxibutirato (BHB) mostraron una correlación significativa con el efecto anticonvulsivo ({tx}).  En este estudio la hipótesis planteada fue, que el efecto anticonvulsivo de la DC clásica y de la dieta con TCM depende del mantenimiento de los niveles sanguíneos de BHB por encima de 2 mM y de acetoacetato por encima de 0,6 mM. Y que uno o ambos de estos compuestos tenían efectos anticonvulsivos directos o producían cambios rápidamente reversibles en el metabolismo del cerebro, que a su vez afectaba la excitabilidad cerebral <sup className="subinx">(1)</sup>. 

                <br></br><br></br>
                
                Sin embargo, varios estudios han demostrado una mala correlación entre las concentraciones de cetonas en plasma sanguíneo y el control de las convulsiones y se han cuestionado el papel central de las cetonas, porque los TCM han demostrado inhibir los receptores AMPA, activación de PPARγ y cambiar la energía celular a través de la biogénesis mitocondrial neuronal, postulando que, a través de estos mecanismos, es probable que los TCM, en lugar de las cetonas, bloqueen el inicio y aumenten el umbral de convulsiones. La evidencia sugiere que los ácidos grasos de cadena media tienen efectos directos y diferentes sobre el metabolismo energético de las células cerebrales <sup className="subinx">(2)</sup>. 
                
                <br></br><br></br>
                Un paso importante para comprender el papel de ácido decanoico en el control de las convulsiones fue el descubrimiento de que puede inhibir directa y selectivamente los receptores AMPA en modelos animales<sup className="subinx">(3)</sup>. 
                
                <br></br><br></br>
                Por otra parte, numerosos estudios han demostrado que la Terapia cetogénica (TC) con TCM es efectiva como la DC Clásica, la cual se ha documentado en varios estudios retrospectivos, prospectivos y aleatorizados. En la implementación de la TC, los aceites que son fuente de TCM son más cetogénicos que los triglicéridos de cadena larga. Por lo tanto, la TC con TCM permite una dieta más flexible lo que favorece la adherencia. Esta modalidad no se basa en proporciones dietéticas como la DC clásica, sino que se calcula con un porcentaje del requerimiento diario y la dosis plena será de acuerdo con la tolerancia del paciente. Existen publicaciones que documentan los efectos secundarios gastrointestinales asociados a ella, como diarrea, vómitos, distención y calambres. Por lo tanto, la TC con TCM ha sido una terapia dietética subutilizada para la epilepsia refractaria. También se han reportado los resultados de utilizar hasta >70% de la DC con TCM para maximizar el control de las convulsiones con efectos secundarios gastrointestinales controlados de manera óptima<sup className="subinx">(4)</sup>.
                
                <br></br><br></br>
                En 2009 se publicó el primer ensayo aleatorizado de DC clásica y DC con TCM para epilepsia con el objetivo de evaluar la eficacia y tolerabilidad después de 3, 6 y 12 meses. En cuanto a la tolerabilidad no hubo diferencias significativas entre los dos tipos de dieta, excepto el aumento de reporte de falta de energía después de 3 meses y vómitos después de 12 meses con el uso del protocolo clásico y en cuanto a la efectividad después de 3, 6 y 12 meses no hubo diferencias estadísticamente significativas en el porcentaje medio de convulsiones basales entre los dos grupos<sup className="subinx">(5)</sup>. 
                
                <br></br><br></br>
                
                Actualmente conocemos que los TCM son efectivos en el control de crisis epilépticas, que activan mecanismos directos e indirectos anticonvulsivantes e intervienen en modificaciones epigenéticas. 
                
                <br></br><br></br>
                El propósito es conseguir los resultados en los pacientes, para alcanzarlo una estrategia es incluir los TCM puros o en una fórmula cetogénica que permite dar una mejor proporción de grasas en la dieta con los beneficios de incluir los TCM, de esta forma se aumenta la aceptación y tolerancia, y por ende la adherencia, logrando el objetivo terapéutico de la Terapia Cetogénica. 
                
                <br></br><br></br>
                <span className="firma-news">
                Adriana Puerta N.D.<br></br>Nutricionista Dietista<br></br> Universidad Nacional de Colombia <br></br>Experta en Terapia Cetogénica Ketogenic Nutrition Training. <br></br>ANA Errores Innatos del Metabolismo. <br></br>INTA Universidad de Chile.
                </span>
                </p>
            </div>
            <WelcomeTypeTwo />
        </section>
    );
}