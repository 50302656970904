import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import ProSelector from "./components/ProSelector";

export default function EIM(){
    gsap.registerPlugin(ScrollToPlugin);
    useEffect(() => {
        gsap.to(window, {duration: 1, scrollTo: "#wearemito"});
    });

    return (
        <section className="wearemito">
            <div id="wearemito" className="wearemito-upnav">
                <h1><span>Línea </span>EIM</h1>
                <div className="wearemito-upnav__close_button">
                    <Link className="wearemito-upnav__link" to="/">VOLVER <span>X</span></Link>
                </div>
            </div>

            <ProSelector />
        </section>
    );
}