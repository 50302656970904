import { Link } from "react-router-dom";

export default function WelcomeTypeOne(){
    return (
        <section className="desc1">

            <div className="sec-desc__img">
                
            </div>

            <div className="sec-desc">
                <div className="sec-desc__box">
                    <h1><span className="sec-desc__titu">Keto </span>News</h1>
                    <p>Triglicéridos de Cadena Media en la Terapia Cetogénica </p>
                    <Link className="circle-button circle-button__org" to="../news">+ Ver más</Link>
                </div>
            </div>
        </section>
    );
}