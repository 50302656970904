import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ProSelector from "../components/ProSelector";

export default function EIM09(){
    const boxRef = useRef();
    gsap.registerPlugin(ScrollToPlugin);
    // wait until DOM has been rendered
    useEffect(() => {
        gsap.to(window, {duration: 1, scrollTo: "#wearemito"});
        //gsap.to(boxRef.current, { opacity: "1.0", duration: 1, delay: 1.0 });
    });

    return (
        <section className="wearemito">
            <div id="wearemito" className="wearemito-upnav">
                <h1>
                    <span>Línea </span>EIM
                </h1>
                <div className="wearemito-upnav__close_button">
                    <Link className="wearemito-upnav__link" to="/EIM">
                        VOLVER <span>X</span>
                    </Link>
                </div>
            </div>

            <div className="wearemito-header__title">
                <h1>
                Epilepsia de ausencia infantíl
                </h1>
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">
                    <div className="wearemito-header__titu-box_area">
                        <h1>
                            <span className="wearemito-header__titu">
                                Definición de la enfermedad 
                            </span>
                        </h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico01.png" />
                    </div>
                </div>

                <p>
                La epilepsia de ausencia infantil (EAI) es una epilepsia infantil familiar
generalizada que se caracteriza por crisis de ausencia muy frecuentes (varias al
día) y que, por lo general, se presenta en niños entre los 4 y 10 años de edad, con
buen pronóstico en la mayoría de los casos.
                </p>
            </div>

            <div className="wearemito-header__title">
                <h1>
                    Resumen
                </h1>
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">
                    <div className="wearemito-header__titu-box_area">
                        <h1>
                            <span className="wearemito-header__titu">
                                Epidemiología 
                            </span>
                        </h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico02.png" />
                    </div>
                </div>

                <p>
                La EAI representa entre un 10% - 17% de todos los casos de epilepsia
diagnosticada en niños en edad escolar. La incidencia se ha estimado en
1/50.000-1/12.500 en los Estados Unidos. Por lo general, las mujeres están más
afectadas que los hombres.
                </p>   
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">
                    <div className="wearemito-header__titu-box_area">
                        <h1>
                            <span className="wearemito-header__titu">
                                Descripción clínica 
                            </span>
                        </h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico03.png" />
                    </div>
                </div>

                <p>
                Por lo general, la EAI se manifiesta en niños entre los 4 y los 10 años de edad, con
un pico entre los 5 y 7 años. La enfermedad se caracteriza por crisis de ausencia
frecuentes (varias al día) con inicio y terminación abrupta. En el 18% de los
afectados por EAI se observa fotosensibilidad. En casos raros, la EAI también está
asociada con un incremento en la tasa de comorbilidades conductuales,
psiquiátricas, del lenguaje, y cognitivas sutiles (tales como problemas de atención,
ansiedad, depresión, aislamiento social y baja autoestima). Estos problemas
cognitivos se complican frecuentemente debido a un trastorno por déficit de
atención e hiperactividad (TDAH). Asimismo, se ha descrito discapacidad
intelectual. En un subgrupo poco frecuente de afectados por E AI se ha obser vado
el inicio de crisis de ausencia antes de los 4 años de edad, una proporción de los
cuales presenta encefalopatía debido a una deficiencia de GL UT1.
                </p>   
            </div>

            <ProSelector />
        </section>
    );
}