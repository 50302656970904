import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ProSelector from "../components/ProSelector";

export default function EIM01(){
    const boxRef = useRef();
    gsap.registerPlugin(ScrollToPlugin);
    // wait until DOM has been rendered
    useEffect(() => {
        gsap.to(window, {duration: 1, scrollTo: "#wearemito"});
        //gsap.to(boxRef.current, { opacity: "1.0", duration: 1, delay: 1.0 });
    });

    return (
        <section className="wearemito">
            <div id="wearemito" className="wearemito-upnav">
                <h1><span>Línea </span>EIM</h1>
                <div className="wearemito-upnav__close_button">
                    <Link className="wearemito-upnav__link" to="/EIM">VOLVER <span>X</span></Link>
                </div>
            </div>
            <div className="wearemito-header__title">
                <h1>Fenilcetonuria</h1>
            </div>
            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">

                    <div className="wearemito-header__titu-box_area">
                        <h1><span className="wearemito-header__titu">Definición de la enfermedad </span></h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico01.png" />
                    </div>
                </div>

                <p>La fenilcetonuria, también denominada «PKU», es un trastorno hereditario poco frecuente que provoca la acumulación de un aminoácido denominado «fenilalanina» en el organismo. La fenilcetonuria es causada por un defecto en el gen que ayuda a crear la enzima necesaria para descomponer la fenilalanina.<br></br><br></br>
Sin la enzima necesaria para procesar la fenilalanina, puede formarse una peligrosa acumulación si una persona con fenilcetonuria come alimentos con alto contenido de proteínas o ingiere aspartamo —edulcorante artificial—. Esto puede provocar, con el tiempo, graves problemas de salud.<br></br><br></br>
Por el resto de sus vidas, las personas con fenilcetonuria (bebés, niños y adultos) necesitan seguir una dieta que limite la fenilalanina, que se encuentra mayormente en alimentos con proteínas.<br></br><br></br>
Los bebés en los Estados Unidos y en muchos otros países son examinados después del nacimiento para detectar si tienen fenilcetonuria. Reconocer la fenilcetonuria de inmediato puede ayudar a prevenir mayores problemas de salud.
</p>
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">

                    <div className="wearemito-header__titu-box_area">
                        <h1><span className="wearemito-header__titu">Síntomas </span></h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico04.png" />
                    </div>
                </div>

                <p>

                Los recién nacidos que tienen fenilcetonuria al principio no presentan ningún síntoma. Sin embargo, sin tratamiento, los bebés suelen manifestar signos de fenilcetonuria en pocos meses.
Los signos y síntomas de la fenilcetonuria pueden ser leves o graves y comprenden los siguientes:<br></br><br></br>
•	Olor similar al de la humedad en el aliento, la piel o la orina de tu hijo, provocado por demasiada fenilalanina en el cuerpo<br></br><br></br>
•	Problemas neurológicos que pueden incluir convulsiones<br></br><br></br>
•	Erupciones cutáneas (eccema)<br></br><br></br>
•	Piel clara y ojos azules, porque la fenilalanina no puede transformarse en melanina (el pigmento responsable del color de la piel y del cabello)<br></br><br></br>
•	Cabeza anormalmente pequeña (microcefalia)<br></br><br></br>
•	Hiperactividad<br></br><br></br>
•	Discapacidad intelectual<br></br><br></br>
•	Retraso en el desarrollo<br></br><br></br>
•	Problemas de comportamiento, emocionales y sociales<br></br><br></br>
•	Trastornos psiquiátricos<br></br><br></br>

</p>
            
                </div>

            <ProSelector />
        </section>
    );
}