import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ProSelector from "../components/ProSelector";

export default function EIM02(){
    const boxRef = useRef();
    gsap.registerPlugin(ScrollToPlugin);
    // wait until DOM has been rendered
    useEffect(() => {
        gsap.to(window, {duration: 1, scrollTo: "#wearemito"});
        //gsap.to(boxRef.current, { opacity: "1.0", duration: 1, delay: 1.0 });
    });

    return (
        <section className="wearemito">
            <div id="wearemito" className="wearemito-upnav">
                <h1>
                    <span>Línea </span>EIM
                </h1>
                <div className="wearemito-upnav__close_button">
                    <Link className="wearemito-upnav__link" to="/EIM">
                        VOLVER <span>X</span>
                    </Link>
                </div>
            </div>

            <div className="wearemito-header__title">
                <h1>
                    Aciduria glutárica tipo 1
                </h1>
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">
                    <div className="wearemito-header__titu-box_area">
                        <h1>
                            <span className="wearemito-header__titu">
                                Definición de la enfermedad 
                            </span>
                        </h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico01.png" />
                    </div>
                </div>

                <p>
                    El déficit de glutaril-CoA deshidrogenasa (GCDH) (GDD) es un trastorno neurometabólico autosómico recesivo que se caracteriza clínicamente por crisis encefalopáticas que dan lugar a lesiones estriatales y trastornos del movimiento discinéticos distónicos graves.
                </p>
            </div>

            <div className="wearemito-header__title">
                <h1>
                    Resumen
                </h1>
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">
                    <div className="wearemito-header__titu-box_area">
                        <h1>
                            <span className="wearemito-header__titu">
                                Epidemiología 
                            </span>
                        </h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico02.png" />
                    </div>
                </div>

                <p>
                    La prevalencia mundial se estima en 1 de 100.000 nacidos. El GDD es más prevalente en la comunidad de la vieja orden Amish, los nativos canadienses Oji-Cree, los viajeros irlandeses y los nativ os americanos Lumbee.
                </p>   
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">
                    <div className="wearemito-header__titu-box_area">
                        <h1>
                            <span className="wearemito-header__titu">
                                Descripción clínica 
                            </span>
                        </h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico03.png" />
                    </div>
                </div>

                <p>
                Los neonatos son en su mayoría asintomáticos, aunque el 75% presenta macrocefalia y posiblemente hipotonía e irritabilidad. Si no se diagnostica antes, la crisis encefalopática aguda inicial se produce entre los 3-36 meses, normalmente precipitada por una enfermedad febril intercurrente, una vacunación o una intervención quirúrgica, y se caracteriza por hipotonía, pérdida de las habilidades motoras y convulsiones que dan lugar a lesiones estriatales con distonía secundaria grave y en ocasiones hemorragia subdural y retiniana. El GDD puede presentarse excepcionalmente con hipoglucemia o acidosis. Con la edad ({`>`} 6 años) y con el tratamiento apropiado, el riesgo de crisis encefalopática disminuye. En algunos pacientes, se desarrolla gradualmente una hipotonía y distonía sin ninguna crisis encefalopática, que es conocida como GDD de aparición tardía o de aparición insidiosa.
                </p>   
            </div>

            <ProSelector />
        </section>
    );
}