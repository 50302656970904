import { Link } from "react-router-dom";

export default function WelcomeTypeTwo(){
    return (
        <section className="desc2">
            <div className="sec-desc__box2">
                <div className="sec-desc__A">
                    <div>
                        <img src="./img/germany.png" />
                        <img src="./img/italy.png" />
                    </div>
                    <h1>Vitaflo</h1>
                    <h2><span className="sec-desc__white">(International Limited),</span> Nestlé Health Science, y Dr. Schär AG</h2>
                    <p>Somos representantes exclusivos para Colombia de Vitaflo antes Dr Schär Deutchland GmbH, ubicado en la ciudad de Rosbach v.d. HÖHE, República Federal de Alemania. </p>
                    <Link className="circle-button" to="../metabolics">+ Ver más</Link>
                </div>

                <div className="sec-desc__C">
                    <img src="./img/product_shot.png" />
                    <p>Línea Metabolics para el tratamiento aminoacidopatías y acidemias orgánicas.
                    </p>
                </div>
            </div>
        </section>
    );
}