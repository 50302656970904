import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Metabolics from './Metabolics';
import News from './News';
import EIM from './EIM';
import Purpura from './Purpura';
import MastHead from './components/MastHead';
import Foot from './components/Foot';
import EIM01 from './eim_contents/EIM01';
import EIM02 from './eim_contents/EIM02';
import EIM03 from './eim_contents/EIM03';
import EIM04 from './eim_contents/EIM04';
import EIM05 from './eim_contents/EIM05';
import EIM06 from './eim_contents/EIM06';
import EIM07 from './eim_contents/EIM07';
import EIM08 from './eim_contents/EIM08';
import EIM09 from './eim_contents/EIM09';

function App() {
  return (
    <div className="">
      <BrowserRouter forceRefresh={true}>
        <MastHead />
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/nosotros' element={<About />}/>
          <Route path='/purpura' element={<Purpura title={"Línea Púrpura"} />}/>
          <Route path='/eim' element={<EIM title={"Línea EIM"} />}/>
          <Route path='/eim01' element={<EIM01 title={"Línea EIM"} />}/>
          <Route path='/eim02' element={<EIM02 title={"Línea EIM"} />}/>
          <Route path='/eim03' element={<EIM03 title={"Línea EIM"} />}/>
          <Route path='/eim04' element={<EIM04 title={"Línea EIM"} />}/>
          <Route path='/eim05' element={<EIM05 title={"Línea EIM"} />}/>
          <Route path='/eim06' element={<EIM06 title={"Línea EIM"} />}/>
          <Route path='/eim07' element={<EIM07 title={"Línea EIM"} />}/>
          <Route path='/eim08' element={<EIM08 title={"Línea EIM"} />}/>
          <Route path='/eim09' element={<EIM09 title={"Línea EIM"} />}/>
          <Route path='/news' element={<News />}/>
          <Route path='/metabolics' element={<Metabolics title={"Línea Metabolics"}  />}/>
          <Route path='/contacto' element={<Contact />}/>
        </Routes>
        <Foot/>
      </BrowserRouter>
    </div>
  );
}

export default App;
