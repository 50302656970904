import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ProSelector from "../components/ProSelector";

export default function EIM05(){
    const boxRef = useRef();
    gsap.registerPlugin(ScrollToPlugin);
    // wait until DOM has been rendered
    useEffect(() => {
        gsap.to(window, {duration: 1, scrollTo: "#wearemito"});
        //gsap.to(boxRef.current, { opacity: "1.0", duration: 1, delay: 1.0 });
    });

    return (
        <section className="wearemito">
            <div id="wearemito" className="wearemito-upnav">
                <h1>
                    <span>Línea </span>EIM
                </h1>
                <div className="wearemito-upnav__close_button">
                    <Link className="wearemito-upnav__link" to="/EIM">
                        VOLVER <span>X</span>
                    </Link>
                </div>
            </div>

            <div className="wearemito-header__title">
                <h1>
                    Acidemia metilmalónica
                </h1>
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">
                    <div className="wearemito-header__titu-box_area">
                        <h1>
                            <span className="wearemito-header__titu">
                                Definición de la enfermedad 
                            </span>
                        </h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico01.png" />
                    </div>
                </div>

                <p>
                La acidemia metilmalónica es un error congénito del metabolismo de la vitamina
B12, caracterizado por manifestaciones gastrointestinales y neurometabólicas
resultantes de una disminución de la función de la enzima mitocondrial
metilmalonil-CoA mutasa.
                </p>
            </div>

            <div className="wearemito-header__title">
                <h1>
                    Resumen
                </h1>
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">
                    <div className="wearemito-header__titu-box_area">
                        <h1>
                            <span className="wearemito-header__titu">
                                Epidemiología 
                            </span>
                        </h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico02.png" />
                    </div>
                </div>

                <p>
                La prevalencia de la acidemia metilmalónica ha sido estimada en 1/48.000 -
1/61.000 en Norteamérica, y en 1/26.000 en China (estos valores pueden incluir
pacientes con acidemia metilmalónica con homocistinuria; consulte este término).
                </p>   
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">
                    <div className="wearemito-header__titu-box_area">
                        <h1>
                            <span className="wearemito-header__titu">
                                Descripción clínica 
                            </span>
                        </h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico03.png" />
                    </div>
                </div>

                <p>
                Los signos clínicos incluyen letargo, retraso del crecimiento, vómitos recurrentes,
deshidratación, insuficiencia respiratoria e hipotonía muscular, así como retraso
del desarrollo, déficit intelectual, hepatomegalia y coma. Las consecuencias a
largo plazo del trastorno incluyen daño neurológico debido a un síndrome
metabólico que afecta al tronco del encéfalo, y finalmente insuficiencia renal
terminal. La enfermedad puede o bien responder o bien ser resistente al
tratamiento con vitamina B12 (acidemia metilmalónica sensible o resistente a
vitamina B12; consulte estos términos). Los pacientes con mut0 o cblB tienden a
estar más gravemente afectados que los pacientes con cblA, cblDv2 o mut-.
                </p>   
            </div>

            <ProSelector />
        </section>
    );
}