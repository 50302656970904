import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ProSelector from "../components/ProSelector";

export default function EIM07(){
    const boxRef = useRef();
    gsap.registerPlugin(ScrollToPlugin);
    // wait until DOM has been rendered
    useEffect(() => {
        gsap.to(window, {duration: 1, scrollTo: "#wearemito"});
        //gsap.to(boxRef.current, { opacity: "1.0", duration: 1, delay: 1.0 });
    });

    return (
        <section className="wearemito">
            <div id="wearemito" className="wearemito-upnav">
                <h1>
                    <span>Línea </span>EIM
                </h1>
                <div className="wearemito-upnav__close_button">
                    <Link className="wearemito-upnav__link" to="/EIM">
                        VOLVER <span>X</span>
                    </Link>
                </div>
            </div>

            <div className="wearemito-header__title">
                <h1>
                La homocistinúria
                </h1>
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">
                    <div className="wearemito-header__titu-box_area">
                        <h1>
                            <span className="wearemito-header__titu">
                            Definición de la enfermedad
                            </span>
                        </h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico01.png" />
                    </div>
                </div>

                <p>La homocistinúria clásica por déficit de cistationina beta-sintasa es una
enfermedad autosómica recesiva frecuente del metabolismo de la metionina. La
cistationina beta-sintasa es un enzima que convierte la homocisteina en
cistationina en la ruta de transulfuración del ciclo de la metionina y requiere de
piridoxal 5-fosfatasa como cofactor.<br></br><br></br>
Los otros dos cofactores involucrados en la ruta de remetilación de la metionina
son la B12 y el ácido fólico. De acuerdo con los datos obtenidos en diferentes
países donde se han analizado más de 200.000 nuevos nacimientos, la tasa
acumulativa actual de la deficiencia CbS es de 1 entre 344.000. En varias áreas
puntuales, la incidencia encontrada es mucho mayor. La homocistinuria clásica
viene acompañada por una gran y variable disfunción tanto clínica como
patológica, la cual presenta una mayor complicación en cuatro sistemas orgánicos:
ojo, esqueleto, sistema nervioso central, y sistema vascular. Otros órganos,
incluyendo el hígado, pelo, y piel también se han visto implicados.<br></br><br></br>
Los objetivos del tratamiento de la homocistinuria clásica varían conforme a la
edad del diagnóstico. Si la deficiencia CbS de diagnostica en los recién nacidos,
como idealmente tendría que ser, el objetivo en este caso va dirigido a prevenir el
desarrollo de las complicaciones oculares, esqueléticas y de tromboembolismo
intravascular, asi como en asegurar un desarrollo normal de la inteligencia. Por
otro lado, si el diagnóstico se realiza tarde, una vez hayan surgido diferentes
complicaciones, los objetivos clínicos van dirigidos a prevenir los episodios
tromboembolíticos que pueden poner en peligro la vida y también en la
prevención de alcanzar una mayor intensidad de las complicaciones ya
instauradas. Para conseguir estos propósitos clínicos con el tratamiento, se tiene
que intentar controlar o eliminar las alteraciones bioquímicas características de la
deficiencia CbS.
                </p>
            </div>
            <ProSelector />
        </section>
    );
}