import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ProSelector from "../components/ProSelector";

export default function EIM08(){
    const boxRef = useRef();
    gsap.registerPlugin(ScrollToPlugin);
    // wait until DOM has been rendered
    useEffect(() => {
        gsap.to(window, {duration: 1, scrollTo: "#wearemito"});
        //gsap.to(boxRef.current, { opacity: "1.0", duration: 1, delay: 1.0 });
    });

    return (
        <section className="wearemito">
            <div id="wearemito" className="wearemito-upnav">
                <h1>
                    <span>Línea </span>EIM
                </h1>
                <div className="wearemito-upnav__close_button">
                    <Link className="wearemito-upnav__link" to="/EIM">
                        VOLVER <span>X</span>
                    </Link>
                </div>
            </div>

            <div className="wearemito-header__title">
                <h1>
                Trastornos del ciclo de la urea
                </h1>
            </div>

            <div className="wearemito-header">
                <div className="wearemito-header__titu-box">
                    <div className="wearemito-header__titu-box_area">
                        <h1>
                            <span className="wearemito-header__titu">
                            Vías metabólicas alternativas
                            </span>
                        </h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico01.png" />
                    </div>
                </div>

                <p>
                Los trastornos del ciclo de la urea (TCU) son enfermedades metabólicas
hereditarias que derivan de una anomalía, cualitativa o cuantitativa, de una de las
seis enzimas que participan en la síntesis de la urea y que supone una acumulación
de amoníaco en el organismo. La incidencia de TCU es escasa, afecta
aproximadamente a uno de cada 25-50.000 nacidos vivos, aunque esta cifra es
probablemente una estimación a la baja, ya que un número importante de
afectados por TCU fallece antes de efectuárseles el diagnóstico<br></br><br></br>
Los trastornos del ciclo de la urea pertenecen a un grupo de enfermedades
conocidas como errores congénitos del metabolismo. Éste incluye todas las
reacciones bioquímicas y fisiológicas que se producen en el organismo. Existen
muchas enfermedades metabólicas, todas ellas con características diferentes
según qué fase del metabolismo esté afectada. El ciclo de la urea es la vía
metabólica en la que el amoníaco tóxico se convierte en urea no tóxica que se
excreta por la orina. El amoníaco procede del nitrógeno de las proteínas.
                </p>
            </div>
            <ProSelector />
        </section>
    );
}