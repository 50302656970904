import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ProSelector from "../components/ProSelector";

export default function EIM03(){
    const boxRef = useRef();
    gsap.registerPlugin(ScrollToPlugin);
    // wait until DOM has been rendered
    useEffect(() => {
        gsap.to(window, {duration: 1, scrollTo: "#wearemito"});
        //gsap.to(boxRef.current, { opacity: "1.0", duration: 1, delay: 1.0 });
    });

    return (
        <section className="wearemito">
            <div id="wearemito" className="wearemito-upnav">
                <h1><span>Línea </span>EIM</h1>
                <div className="wearemito-upnav__close_button">
                    <Link className="wearemito-upnav__link" to="/">VOLVER <span>X</span></Link>
                </div>
            </div>
            <div className="wearemito-header__title">
                <h1>Enfermedad de la orina con olor a jarabe de arce clásica</h1>
            </div>
            <div className="wearemito-header">    
                
                <div className="wearemito-header__titu-box">

                    <div className="wearemito-header__titu-box_area">
                        <h1><span className="wearemito-header__titu">Definición de la enfermedad </span></h1>
                    </div>

                    <div className="wearemito-header__titu-ico_area">
                        <img className="icoimg" src="./img/icons/ico01.png" />
                    </div>
                </div>

                <p>La enfermedad de la orina con olor a jarabe de arce clásica (MSUD clásica) es la forma más grave y probablemente más común de MSUD (véase este término), y está caracterizada por un olor a jarabe de arce en el cerumen al nacer, rechazo de las tomas, letargo y distonía focal, seguido por encefalopatía progresiva e insuficiencia respiratoria central si no se trata.</p>
            </div>

            <div className="wearemito-header__title">
                <h1>Resumen</h1>
            </div>

            <div className="wearemito-header">
            <div className="wearemito-header__titu-box">

            <div className="wearemito-header__titu-box_area">
    <h1><span className="wearemito-header__titu">Epidemiología
 </span></h1>
</div>

<div className="wearemito-header__titu-ico_area">
    <img className="icoimg" src="./img/icons/ico02.png" />
</div>
</div>

                <p>La enfermedad de la orina con olor a jarabe de arce clásica (MSUD clásica) es la forma más grave y probablemente más común de MSUD (véase este término), y está caracterizada por un olor a jarabe de arce en el cerumen al nacer, rechazo de las tomas, letargo y distonía focal, seguido por encefalopatía progresiva e insuficiencia respiratoria central si no se trata.</p>
            </div>

            <div className="wearemito-header">
            <div className="wearemito-header__titu-box">

<div className="wearemito-header__titu-box_area">
    <h1><span className="wearemito-header__titu">Descripción clínica </span></h1>
</div>

<div className="wearemito-header__titu-ico_area">
    <img className="icoimg" src="./img/icons/ico03.png" />
</div>
</div>
                <p>La aparición de la MSUD clásica se produce en el periodo neonatal (normalmente durante las 12 horas posteriores al nacimiento), con presencia del olor a jarabe de arce en el cerumen y, posteriormente, en la orina, rechazo alimentario y somnolencia. En los primeros días de vida se produce encefalopatía progresiva con letargo, apnea intermitente, estereotipias (descritas como "posición de esgrima y pedaleo") y opistótonos. Sin tratamiento, entre los días 7 y 10 se produce coma e insuficiencia respiratoria central. Más tarde, el estrés catabólico, una infección o una lesión pueden causar intoxicación con leucina, aguda y potencialmente mortal, con vómitos, alteración de la consciencia, ataxia y distonía aguda en bebés, y alucinaciones, hiperactividad, distonía focal, ataxia y coreoatetosis en niños y adultos.</p>
            </div>

            <ProSelector />
        </section>
    );
}